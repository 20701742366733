import './App.css';


//Package Dependencies
import {
  BrowserRouter as Router,
  Routes,
  Route
}
from "react-router-dom";

import {
  SideNavigation,
  AppLayout
} from "@awsui/components-react";


//React Dependencies
import { useEffect, useState, useCallback } from 'react';

//Components
import TopNavigation from "./components/TopNavigation";

//Pages
import Home from './pages/Home';
import Assessments from './pages/Assessments';
import Assessment from './pages/Assessment';
import NewAssessment from './pages/NewAssessment';
import Feedback from './pages/Feedback';
import CustomerView from './pages/CustomerView';
import SideCharts from './components/SideCharts';
import BottomCharts from './components/BottomCharts';

import getMidwayToken from "./components/MidwayToken";

const midwayToken = getMidwayToken();
//console.log('midway token')
//console.log(midwayToken)



let alias = ""
  if (midwayToken && midwayToken.payload && midwayToken.payload.sub) {
    console.log("***VERSION***")
    console.log("0.0.2")
    alias = midwayToken.payload.sub;
    //alias = "skarra"
    //console.log(alias)
    //devLogger(midwayToken.payload)
    //devLogger(alias)
   //alias = 'ashmehra';
    sessionStorage.setItem("alias", alias);
  }



let  App = () => {

  const [scores, setScores] = useState(0)

  let onValueUpdate = useCallback(e => {
    //console.log(e)
    setScores(e)
  }, [])

  let sideNavItems = 
  [
    {/* type: "link", text: "All items", href: "/list".*/},
    { type: "link", text: "Assessments", href: "/assessments/"},
    { type: "divider" },
    { type: "link", text: "Create New", href: "/new"},
    { type: "divider" },
    { type: "link", text: "Feedback", href: "/feedback"}
  ]

  return(
    <>
    <div >
      <div id="h" style={{ position: "sticky", top: 0, zIndex: 1002 }}>
        <TopNavigation alias={alias} />     
      </div>
      
        

        <div>
        <AppLayout 
        headerSelector="#h"
        footerSelector='#f'
          toolsHide={true}
            navigation={
              <div id="s">
                <SideNavigation items={sideNavItems} />
              </div>
            }
          content={
            <Router>
            <Routes>
              <Route path="/assessments/"
                element={<Assessments />}
              >
              </Route>
              <Route path="/new/"
                element={<NewAssessment />}
              >
              </Route>
              <Route path="/assessment/"
                element={<Assessment onValueUpdate={onValueUpdate}/>}
              >
              </Route>
              <Route path="/feedback/"
                element={<Feedback />}
              >
              </Route>
              <Route path="/customer/"
                element={<CustomerView />}
              >
              </Route>
              <Route path="/"
                element={<Home />}
              >
              </Route >
              
            </Routes>
            </Router>

        }
      />
      </div>

      <div id="f" style={{ backgroundColor: "blue", position: "sticky", bottom:0, zIndex: 1002, height: "0px" }}>
      </div>
      
    </div>
    </>
  );
}

export default App;
