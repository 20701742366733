import 'survey-core/defaultV2.min.css';
import { Model, StylesManager, Styles } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { Converter } from "showdown";

import {
  Button,
  Header,
  SpaceBetween,
  Tabs
}
from "@awsui/components-react";

import { useLocation } from "react-router-dom";

import surveyTemplate from '../components/templates/assement-default'

import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";

import { useEffect, useState, } from 'react';

import { getAssessmentById, getTemplateById, updateAssessment } from "../utils/Api";

import BottomCharts from '../components/BottomCharts';

import "survey-core/defaultV2.css";



import SurveyWrapper from './SurveyWrapper';

import Recommendations from './Recommendations';




let Assessment = (props) => {

  const [scores, setScores] = useState(0)
  const [ name, setName ] = useState("")
  let onValueUpdate = useCallback(e => {
    //console.log(e)
    //props.onValueUpdate(e)
    setScores(e)
  }, [])

  let onNameChange = useCallback(e => {
    //console.log(e)
    //props.onValueUpdate(e)
    setName(e)
  }, [])


  let  getCjnFraction = (p,currentStage) => {
    if (Math.floor(p) > currentStage) {
        return(0.25);
    } else 
    {
        return((p-Math.floor(p))/4);
    }
  }

  let p1 = scores[1]
  let p2 = scores[2]
  let p3 = scores[3]
  let p4 = scores[4]

  let cjnScore = Math.min(Math.floor(p1),Math.floor(p2),Math.floor(p3),Math.floor(p4));
  let  cjnFractionScore = getCjnFraction(p1,cjnScore) + 
                          getCjnFraction(p2,cjnScore) + 
                          getCjnFraction(p3,cjnScore) + 
                          getCjnFraction(p4,cjnScore); 
  cjnScore = cjnScore + cjnFractionScore;

  

  return (
    <div>
      <Header
      variant="h1"
      >
        {name} | CJN Score: {cjnScore}
      </Header>
          <Tabs
      tabs={[
        {
          label: "Assessment",
          id: "assessment",
          content: <SurveyWrapper onValueUpdate ={onValueUpdate} setCustomerName={onNameChange}/>
        },
        {
          label: "Recommendations",
          id: "recommendations",
          content: <Recommendations />
        }
      ]}
    />
      
      <div>
          <BottomCharts scores={scores}/>
      </div>
    </div>
  )
  }
export default Assessment;