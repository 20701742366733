import { BarChart } from "@awsui/components-react";



let BottomCharts = (props) => {
  

let  getCjnFraction = (p,currentStage) => {
  if (Math.floor(p) > currentStage) {
      return(0.25);
  } else 
  {
      return((p-Math.floor(p))/4);
  }
}


 let p1 = props.scores[1]
 let p2 = props.scores[2]
 let p3 = props.scores[3]
 let p4 = props.scores[4]

let cjnScore = Math.min(Math.floor(p1),Math.floor(p2),Math.floor(p3),Math.floor(p4));
let  cjnFractionScore = getCjnFraction(p1,cjnScore) + 
                        getCjnFraction(p2,cjnScore) + 
                        getCjnFraction(p3,cjnScore) + 
                        getCjnFraction(p4,cjnScore); 
cjnScore = cjnScore + cjnFractionScore;




    //console.log(props.scores)

    return (
        <div style={{width: "70%",
             height: "200px", 
             backgroundColor: "white",
             display: "block",
             position: "fixed",
             bottom: "0",
             paddingLeft: "auto",
             paddingRight: "150px",
             zIndex: "1000",
             display: "flex",
            justifyContent: "space-between"

        }}>
          <div style={{
            width: "50%"
            
          }}>
            <BarChart 

              series={[
                {
                  title: "Score",
                  type: "bar",
                  data: [
                    { x: "", y: cjnScore }
                  ]
                },
              ]}
              hideFilter
              hideLegend
              xDomain={[""]}
              yDomain={[1, 5]}
              height={30}
              horizontalBars
              xScaleType="categorical"
              yTitle=""
              detailPopoverSize=""
              />
          </div>
          <div style={{
            width: "50%"
            
          }}>
            <BarChart 

              series={[
                {
                  title: "Business & People",
                  type: "bar",
                  data: [
                    { x: "Business & People", y: props.scores[1] || 1 }
                  ]
                },
                {
                  title: "Security",
                  type: "bar",
                  data: [
                    { x: "Security", y: props.scores[2] || 1}
                  ]
                },
                {
                  title: "Platform",
                  type: "bar",
                  data: [
                    { x: "Platform", y: props.scores[3] || 1}
                  ]
                },
                {
                  title: "Operations & Governance",
                  type: "bar",
                  data: [
                    { x: "Operations & Governance", y: props.scores[4] || 1}
                  ]
                }
              ]}
            hideFilter
            hideLegend
            xDomain={["Business & People", "Security", "Platform", "Operations & Governance"]}
            yDomain={[1, 5]}
            height={200}
            horizontalBars
            xScaleType="categorical"
            yTitle=""
            />
                      
          </div>
        </div>
    )
}

export default BottomCharts