import {
    Button,
    Header,
    SpaceBetween,
    Form,
    Container,
    FormField,
    Input,
    Select
  }
  from "@awsui/components-react";

import { useEffect, useState, } from 'react';
import { useNavigate } from "react-router-dom";

import { addAssessment, getCustomers } from "../utils/Api";

let alias = sessionStorage.getItem('alias')

  let emptyAssessment ={
    owner: [alias],
    group:"",
    subgroup:"",
    "status": "In-Progress",
    "customerName": "",
    "customerId": "",
    template: "88c01772-8ba1-451b-af40-b58d7a87e1b0",
    surveyData: {},
    "score": "",
    summary: "",
    recommendations: [],
    pdf: "",
    ppt: "",
    timeline: "",
    latest: true
}


let NewAssessment = () => {
    
    const navigate = useNavigate();

    const [ assessment, setAssessment ] = useState(emptyAssessment)
    const [ customers, setCustomers ] = useState([])
    const [ searchingCustomers, setSearchingCustomers ] = useState(false)
    const [ selectedCustomers, setSelectedCustomers ] = useState([])

    //console.log(assessment)

   
let addAssessmentWrapper = (assessment) => {
    addAssessment(assessment).then(
        (result) => {
            //console.log(result)
            //console.log(result.body)
            navigate('/assessment/', { state: { "id": result.body } });
        },
        (error) => {
            console.log(error)
        }
    )
}

let getCustomersWrapper = (chars) => {
    //devLogger("Searching for Managers")
    //wait for 3 charws to be typed
    //setSearchingCustomers(true)
    if(chars.length > 2){
      getCustomers(chars).then(
        (result) => {
            //console.log(result)
            let customers = []
            result.forEach((t) => {
              customers.push({ label: t.name, 
                value: t.sfdcId, 
                description: t.sfdcId,
                accountId: t.accountId,
                contactEmail: t.contactEmail,
                primaryContact: t.primaryContact,
                id: t.id
            })
            })
            setCustomers(customers)
            setSearchingCustomers(false)
        },
        (error) => {
            console.log(error);
        }
      );
    } 
}
  
let findCustomerById = (id) => {
    //console.log(id)
    //console.log('finding customers')
    //console.log(selectedCustomers)
    selectedCustomers.forEach((x, i) => {
      if (x['value'] === id){
        return x
      } else {
        return { label: id, value: id }; //fallback
      }
    })
}

    return (
        <div>
            <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                  </SpaceBetween>
                }
                errorText={""}
              >
            <SpaceBetween direction="vertical" size="l"> 
                <Container header={
                    <Header 
                        variant="h2"
                        actions={
                            <Button 
                            variant="primary" 
                            onClick={async () => {
                                //console.log(assessment)
                                addAssessmentWrapper(assessment)
                                
                            }}>
                              Start  Assessment
                            </Button>
                        }
                    >
                        New Assessment
                    </Header>
                }>
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="Owner" errorText={""}>
                                <Input value={assessment.owner} 
                                disabled
                        />
                        </FormField>
                        <FormField label="Customer" description="Select a Customer from the list or add new." errorText={""}>
                            <Select 
                            filteringType="manual"
                            placeholder="Search for Customers"
                            loadingText="Searching for Customers" 
                            statusType={searchingCustomers? "loading" : "finished"}
                            onChange={(event) => {
                                console.log(event.detail.selectedOption)
                                let customersArray = selectedCustomers
                                customersArray.push(event.detail.selectedOption)
                                setSelectedCustomers(customersArray)
                                let managerAliases =[]
                                //event.detail.selectedOptions.forEach((x, i) => {
                                //managerAliases.push(x.value)
                                //})
                                setAssessment({...assessment, 
                                    customerName: event.detail.selectedOption.label, 
                                    customerId: event.detail.selectedOption.id,
                                    sfdcId: event.detail.selectedOption.value,
                                    surveyData: {
                                        CustomerName: event.detail.selectedOption.label,
                                        "AccountID": event.detail.selectedOption.accountId,
                                        "Customer Email": event.detail.selectedOption.contactEmail,
                                        "CustomerContact": event.detail.selectedOption.primaryContact
                                    
                                    }
                                })
                            }}
                            options={customers}
                            selectedOption={{label: assessment.customerName}}
                            onLoadItems={(e) => {
                                //console.log(e.detail.filteringText)
                                if(e.detail.filteringText.length > 2){
                                //console.log('searching for user')
                                getCustomersWrapper(e.detail.filteringText)
                                } else {
                                setSearchingCustomers(true)
                                //setCustomers([])
                                }
                            }}
                            />
                        </FormField>
                        <Button>
                            Add Customer
                        </Button>
                        <FormField label="Customer Name" errorText={""}>
                                <Input value={assessment.customerName} 
                                onChange={(event) => setAssessment({ ...assessment, "customerName": event.detail.value, surveyData: {CustomerName: event.detail.value}})}
                        />
                        </FormField>
                        <FormField label="Customer Id" errorText={""}>
                                <Input value={assessment.customerId} 
                                onChange={(event) => setAssessment({ ...assessment, "customerId": event.detail.value})}
                        />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
              </Form>

        </div>
    )



}

export default NewAssessment;