
import * as React from "react";
import ReactDOM from 'react-dom';

import {
 Box,
 Button,
 ColumnLayout,
 Container,
 FormField,
 Grid ,
 Header,
 SpaceBetween ,
 Select,
 Link
} from "@awsui/components-react";




import cjnStages from '../resources/home/cjnstages.png'
import CloudValue1  from '../resources/home/cjn1.png';
import  CloudValue2  from '../resources/home/cjn2.png';
import  CloudValue3  from '../resources/home/cjn3.png';

// ​
// import '../../styles/landing-page.scss';
// import { appLayoutAriaLabels } from '../../i18n-strings';
const cloudfrontDistributions = [{ id: '1', label: 'Web delivery method' }];
const Home = () => {
  const [selectedOption, setSelectedOption] = React.useState(cloudfrontDistributions[0]);

  return (
    <Box margin={{ bottom: 'l' }}>
      <div className="custom-home__header">
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
                // { colspan: { default: 3, xxs: 9 }, offset: { l: 2, xxs: 1 }  }, 
                // { colspan: { default: 9, xxs: 3 },offset: { s: 0, xxs: 1 } }
            //   { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
              { colspan: { xl: 10, l: 8, s: 8, xxs: 10 }, offset: { l: 2, xxs: 1 } },
              { colspan: { xl: 2, l: 4, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
            ]}
          >


            <div className="custom-home__header-title">
              <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                AWS Cloud Journey Navigator
              </Box>
              <Box variant="h2" fontWeight="heavy" padding={{ bottom: 's' }} fontSize="heading-m" color="inherit">
                Rapid Cloud Adoption Assessment
              </Box>
              <Box variant="p" fontWeight="light">
                <span className="custom-home__header-sub-title">
                   Your ability to effectively leverage cloud is underpinned by a set of foundational organizational capabilities. 
                   Your cloud journey will be unique because you will need to envision your desired target state, 
                   understand your true cloud readiness, and adopt an agile approach to closing the gaps.
                    <br></br>
                    <br></br>
                  The AWS Cloud Journey Navigator is a guided assessment to capture your existing AWS cloud capabilities in 4 
                  functional perspectives at 4 levels of cloud adoption. The functional perspectives align with the AWS Cloud Adoption 
                  Framework (CAF).
                </span>
              </Box>
            </div>

          </Grid>
        </Box>
      </div>
​
      <Box padding={{ top: 'xxxl', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 10, l: 8, s: 8, xxs: 10 }, offset: { l: 2, xxs: 1 } },
            // { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
          ]}
        >
          <SpaceBetween size="xxl">
            <div>
              <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
              Cloud Journey Navigator Perspectives and Stages of Adoption
              </Box>
              <Container>
                <div
                  role="img"
                  aria-label="Cloud Journey Navigator Perspectives and Stages of Adoption"
                >
                    <img src={cjnStages} />
                  {/* <div className="awsui-util-hide-in-dark-mode" aria-hidden="true">                    
                    

                  </div>
                  <div className="awsui-util-show-in-dark-mode" aria-hidden="true">
                    {/* <DiagramDark /> }
                  </div> */}
                </div>
              </Container>
            </div>
​
            <div>
              <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                CJN Stages of Adoption Explained              
              </Box>
              <Container>
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Pilot
                    </Box>
                    <Box variant="p">
                        The organization has decided to move to cloud and wants to move forward with a first project to learn about the benefits.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Adopt
                    </Box>
                    <Box variant="p">
                    The organization has begun to see the benefits of cloud, made foundational investments for broader adoption, and is using cloud for key business applications/functions..
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Modernize
                    </Box>
                    <Box variant="p">
                    The organization is continuously optimizing their cloud workloads and makes investments to sustain long-term IT operations in the cloud to fully realize the benefits of a cloud-first imperative.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Reinvent
                    </Box>
                    <Box variant="p">
                        The organization utilizes a cloud-first mentality to leverage cloud as a business differentiator and transformation agent.
                    </Box>
                  </div>
                </ColumnLayout>
              </Container>
            </div>

            <div>
              <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                  CJN Functional Perspectives Explained         
               </Box>
              <Container>
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                        Business & People
                    </Box>
                    <Box variant="p">
                      The Business Perspective helps ensure that customers cloud investments accelerate their digital transformation ambitions and business outcomes.
                        <br></br>
                      The People Perspective serves as a bridge between technology and business, accelerating the cloud journey to help organizations more rapidly evolve to a culture of continuous growth, learning, and where change becomes business-as-normal, with focus on culture, organizational structure, leadership, and workforce.
                    </Box>
                    <Link>Learn more</Link>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                        Operations and Governance
                    </Box>
                    <Box variant="p">
                        The Operations Perspective helps ensure that cloud services are delivered at a level that meets the needs of the business.
                        <br></br>
                        The Governance Perspective helps customers orchestrate their cloud initiatives while maximizing organizational benefits and minimizing transformation related risks.
                    </Box>
                    <Link>Learn more</Link>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                        Security
                    </Box>
                    <Box variant="p">
                        The Security Perspective helps customers achieve the confidentiality, integrity, and availability of their data and cloud workloads.                     </Box>
                    <Link>Learn more</Link>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                        Platform
                    </Box>
                    <Box variant="p">
                        The Platform Perspective helps customers build an enterprise-grade, scalable, hybrid cloud platform, modernize existing workloads, and implement new cloud-native solutions.                     </Box>
                    <Link>Learn more</Link>
                  </div>
                </ColumnLayout>
              </Container>
            </div>
            <div>
            <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                What is Cloud Value
            </Box>
            <Container>
                <div
                  role="img"
                  aria-label="What is Cloud Value"
                >
                    <img src={CloudValue1} />
                    <img src={CloudValue2} />
                    <img src={CloudValue3} />
                </div>
              </Container>
              </div>
          </SpaceBetween>
          
          
          </Grid>
      </Box>
    </Box>
  );
};

export default Home
