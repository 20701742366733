import {
    
    Table,
    Box,
    Button,
    Header,
    SpaceBetween,
    Modal,
    FormField,
    Multiselect,

    
  } from "@awsui/components-react";

  import * as React from "react";
  import { useState, useEffect } from 'react';

  import { getAssessmentByAlias, getUsersByName, updateAssessment } from "../utils/Api";

  import { useNavigate, NavLink } from "react-router-dom";

  import { addAssessment } from "../utils/Api";

  let alias = sessionStorage.getItem('alias')


let Assessments = (props) => {

  const navigate = useNavigate();

  const [ selectedItems, setSelectedItems ] = useState([]);
  const [ assessments, setAssessments ] = useState([])
  const [ loadingAssessments, setLoadingAssessments ] = useState(false)
  const [ isShareModalVisible, setIsShareModalVisible ] = useState(false)
  const [ users, setUsers ] = useState([])
  const [ selectedUsers, setSelectedUsers ] = useState([])
  const [ searchingUsers, setSearchingUsers ] = useState(false)
  const [ updatedAssessment, setUpdatedAssessment ] = useState({})



  useEffect(() => {
    //console.log('getting assessments')
    setLoadingAssessments(true)
    getAssessmentByAlias(alias).then(
      
      (result) => {
          //console.log(result)
          setAssessments(result)
          setLoadingAssessments(false)
      },
      (error) => {
          console.log("error")
          console.log(error);
          setLoadingAssessments(false)
      }
    )

  }, []);

    let updateAssessmentWrapper = (assessmentObj) => {
      //console.log(assessmentObj)
      updateAssessment(assessmentObj).then(
        (result) => {
          //console.log(result)
          //console.log(id)
        },
        (error) => {
            console.log(error)
        }
    )
  }

  let getUsersWrapper = (chars) => {
    if(chars.length > 2){
      getUsersByName(chars).then(
        (result) => {
            //console.log(result)
            let users = []
            result.forEach((t) => {
              users.push({ label: t.name, value: t.alias, description: t.alias})
            })
            setUsers(users)
            setSearchingUsers(false)
        },
        (error) => {
            console.log(error);
        }
      );
    }
  }
  let addNewVersion = (assessment) => {
    //console.log("adding assessment")
   //console.log(assessment)

    addAssessment(assessment).then(
        (result) => {
            //console.log(result)
            //console.log(result.body)
            navigate('/assessment/', { state: { "id": result.body } });
        },
        (error) => {
            console.log(error)
        }
    )

  }

    return(
        <div>
            <Table
              onSelectionChange={({ detail }) =>
                setSelectedItems(detail.selectedItems)
              }
              selectionType="single"
              selectedItems={selectedItems}
              loading={loadingAssessments}
              loadingText="Loading Assessments"
              columnDefinitions={[
                {
                  id: "id",
                  header: "id",
                  cell: e => e.id,
                  sortingField: "id"
                },
                {
                  id: "customerName",
                  header: "Customer name",
                  cell: e => <NavLink to="/customer"  state ={{ "customerId": e.customerId }} >{e.customerName}</NavLink>,
                  sortingField: "customerName"
                },
                {
                  id: "owner",
                  header: "Owner",
                  cell: e => e.owner.toString(),
                  sortingField: "owner"
                },
                {
                  id: "group",
                  header: "Group",
                  cell: e => e.group,
                  sortingField: "group"
                },
                {
                  id: "status",
                  header: "Status",
                  cell: e => e.status,
                  sortingField: "status"
                },
                {
                  id: "score",
                  header: "Score",
                  cell: e => e.score['total'],
                  sortingField: "score"
                },
                {
                  id: "created",
                  header: "Created",
                  cell: e => e.created,
                  sortingField: "created"
                },
              ]}
              visibleColumns={[
                "customerName",
                "created",
                "status",
                "customerId",
                "owner",
                "group",
                "score"
              ]}
              items={assessments}
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No resources</b>
                  <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                  >
                    No Assessments to display.
                  </Box>
                </Box>
              }
              
              header={
                <Header
                  actions={
                    <SpaceBetween
                      direction="horizontal"
                      size="xs"
                    >
                      <Button
                        onClick={() => {
                          setIsShareModalVisible(true)
                        }}
                        disabled={selectedItems.length==1? false: true}
                      >
                        Share
                      </Button>
                      <Button
                        onClick={() => {
                          navigate('/assessment/', { state: { "id": selectedItems[0]['id'] } });
                        }}
                        disabled={selectedItems.length==1? false: true}
                      >
                        Open
                      </Button>
                      <Button
                        disabled={selectedItems.length==1? false: true}
                        onClick={() => {
                          let newAssessment = selectedItems[0]
                          newAssessment['status'] = "In Progress"
                          newAssessment['latest'] = true
                          addNewVersion(newAssessment).then(
                            (result) => {
                                //console.log(result)
                                //console.log(result.body)
                                navigate('/assessment/', { state: { "id": result.body } });
                            },
                            (error) => {
                                console.log(error)
                            }
                        )
                        }}
                      >
                        New Version
                      </Button>
                      <Button
                      variant="primary"
                      onClick={() => {
                        navigate('/new/');
                      }}
                      >
                        Create New
                      </Button>
    
                    </SpaceBetween>
                  }
                >
                  Customer Assessments
                </Header>
              }
          />
        <Modal
                onDismiss={() => {
                    setIsShareModalVisible(false)
                    //setNewTask(emptyTask)
                }}
                visible={isShareModalVisible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                            onClick={() => {
                                setIsShareModalVisible(false)
                                //setNewTask(emptyTask)
                                //setNewTaskNameError("")
                                //setNewTaskDateError("")
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary"
                            onClick={()=>{

                              updateAssessmentWrapper(updatedAssessment)
                              setIsShareModalVisible(false)
                              setUpdatedAssessment({})
                              //if (validateNewTaskForm()){
                                /* console.log(newTask)
                                let newTasksList = newTasks
                                newTask['id'] = newTasks.length
                                newTasksList.push(newTask)
                                setNewTasks(newTasksList)
                                setCampaign({...campaign, ['tasks']: newTasksList})
                                setModalVisible(false)
                                setNewTask(emptyTask)
                                console.log(campaign) */
                              //}
                                
                            }}
                        >Add</Button>
                    </SpaceBetween>
                    </Box>
                }
                header="Share Assessment"
            >
              <FormField label="Users" description="" errorText={""}>
                <Multiselect 
                  filteringType="manual"
                  placeholder="Search for Users"
                  loadingText="Searching for Users" 
                  statusType={searchingUsers? "loading" : "finished"}
                  onChange={(event) => {
                    //console.log(event.detail.selectedOptions)
                    setSelectedUsers(event.detail.selectedOptions)
                    let userAliases =[]
                    event.detail.selectedOptions.forEach((x, i) => {
                      userAliases.push(x.value)
                    })
                    //console.log(selectedItems)
                    let newAssessment = structuredClone(selectedItems?.[0])
                    let owners = newAssessment['owner']
                    userAliases.forEach((alias, i)=> {
                      owners.push(alias)
                    })
                    newAssessment['owner'] = owners
                    
                  //console.log(newAssessment)
                    setUpdatedAssessment(newAssessment)
                    //setCampaign({...assessment, coowners: userAliases})
                  }}
                  options={users}
                  selectedOptions={selectedUsers}
                  onLoadItems={(e) => {
                    //console.log(e.detail.filteringText)
                    if(e.detail.filteringText.length > 2){
                      //console.log('searching for user')
                      getUsersWrapper(e.detail.filteringText)
                    } else {
                      setSearchingUsers(true)
                      setUsers([])
                    }
                  }}
                />
              </FormField>
                    
            </Modal>  
        </div>
    )

}

export default Assessments