import {
    Button,
    Header,
    SpaceBetween,
    Tabs,
    Box,
    ColumnLayout,
    Container,
    StatusIndicator,
    ProgressBar,
    Link,
    Popover
  }
  from "@awsui/components-react";

import { useNavigate, NavLink } from "react-router-dom";

let AssessmentDetails = (props) => {

    const navigate = useNavigate();

    let assessment = props.assessment
    let id = assessment['id']
    return (
        <div>
            <Header
                variant="h1"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button 
                            variant="primary"
                            onClick={() => {
                                navigate('/assessment/', { state: { "id": id } });
                              }}
                        
                        >
                            Open
                        </Button>
                    </SpaceBetween>
                }
            >
                CJN Score: {assessment?.['score']?.['total']}
            </Header>
        </div>
    )
}

export default AssessmentDetails