import {
  Button,
  Header,
  SpaceBetween,
  Checkbox,
  FormField,
  DatePicker

}
from "@awsui/components-react";

import { useEffect, useState, } from 'react';

import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { getRecommendationsById, getAssessmentById, updateAssessment, assessmentId, getReadout } from "../utils/Api";

let Recommendations = () => {

const state = useLocation();
const navigate = useNavigate();

const [ recommendations, setRecommendations ] = useState([])

const [assessment, setAssessment ] = useState({})

const [ id, setId ] = useState(state['state']['id'])



//console.log(id)

useEffect(() => {

  getAssessmentById(id).then(
    (assessment) => {
        //console.log(result)
        
        setAssessment(assessment)
    },
    (error) => {
        console.log("error")
        console.log(error);
    }
  )

  getRecommendationsById(id).then(
      (rec) => {
          //console.log(result)
          setRecommendations(rec)
      },
      (error) => {
          console.log("error")
          console.log(error);
      }
  )
}, []);


let saveAssessment = (updatedAssessment) => {

  updateAssessment(updatedAssessment).then(
    (result) => {
      console.log(result)
      //console.log(id)
    },
    (error) => {
        console.log(error)
    }
  )
}


  return (
      <div>
              <Header
          variant="h1"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
              variant='primary'
              onClick={(e)=> {

                getReadout(id).then(
                  (result) => {
                      console.log(result)
                      window.open(result, "_blank")
                      
                      //setAssessment(assessment)
                  },
                  (error) => {
                      console.log("error")
                      console.log(error);
                  }
                )

              }}
              >
                Generate Readout
              </Button> 
            </SpaceBetween>
          }
        >
          
          
        </Header>
          <br />
        <b>
          The recommended concepts below are in order based on your current level of concept adoption in each functional perspective. As the next step, please mark the recommended concepts you identify as priorities with a checkmark. Once you have identified the priority concepts, identify the date you would like to complete the concepts in the field below.
        </b>
        <FormField
          label=""
        >
          <DatePicker
            onChange={({ detail }) => {
              console.log(detail)
              let newAssessment = structuredClone(assessment)
              newAssessment['timeline'] = detail?.value
              setAssessment(newAssessment)
              saveAssessment(newAssessment)
            }}
            value={assessment?.['timeline']}
            placeholder="YYYY/MM/DD"
          />

          <br />
        </FormField>

        {
          assessment?.['recommendations']?.map((recommendation, i)=>{
            //console.log(recommendation)
            return(
          <div>
            <Checkbox
              key={recommendation['id']}
              onChange={(event) =>{
                console.log(i)
                console.log(event)
                let id = recommendation['id']
                let newAssessment = structuredClone(assessment)
                newAssessment['recommendations'][i]['selected'] = event.detail.checked
                setAssessment(newAssessment)
                saveAssessment(newAssessment)
                //console.log(newAssessment)
                //setCheckedItems({ ...checkedItems, [id]: detail.checked})

              }}
              checked={recommendation['selected']}
            >
              <b>{recommendations[i]?.title}</b>
              <br />
              {recommendations[i]?.description}
              <br />
            
            <b>Recommendations and Resources</b>
            <br />
            <>
            {recommendations[i]?.resources.map((x, i) => {
              //console.log(x.name)
              return <div><a target="_blank" href={x.link}>{x.name}</a><br /></div>
            })
            
            }
            </>
            </Checkbox>
            <br />
            </div>
          )})
          }
      </div>
  )
}

export default Recommendations