import { devLogger } from "./Logging"

const STAGE = process.env.REACT_APP_STAGE
devLogger("stage")
devLogger(STAGE)
let SERVICE_URLS ={}

const PROD_URLS = {
    
  }
  
  const DEV_URLS = {
    "CJN_API_SERVICE_URL": "https://w7846h38ka.execute-api.us-east-1.amazonaws.com/prod"
  }

  if(STAGE === "PROD"){
    SERVICE_URLS = PROD_URLS
  } else if(STAGE === "DEV") {
    SERVICE_URLS = DEV_URLS
  } else {
    console.log("ERROR: Operating Mode Not Defined. Specify Prod or Dev.")
  }

  async function internalFetch(fetchUrl) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("midway_id_token")
          },
        })
        .then(
          res => res.json(),
          error => {
            console.log("fetch", error);
            reject(error);
          }
        )
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else if (result && result.body) {
              resolve(result.body);
            }
            else {
              resolve(result);
            }
          },
          (error) => {
            console.log("fetch", error);
            reject(error);
          }
        );
    });
  }

  async function addAssessment(assessment) {
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL + "/assessment";
    console.log("*******")
    //console.log(assessment)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(assessment)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              //console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }

  async function updateAssessment(assessment) {
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL + "/assessment";
    console.log("*******")
    //console.log(assessment)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(assessment)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              //console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }

  async function getAssessmentById(id) {
    if(id === undefined) {
      id = ""
    }
    if(id.includes('+')){
      id = id.replace('+', '%2B')
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL+ "/assessment?id=" + id
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }

  async function getAssessmentByAlias(alias) {
    if(alias === undefined) {
      alias = ""
    }
    if(alias.includes('+')){
      alias = alias.replace('+', '%2B')
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL+ "/assessment/search/alias?alias=" + alias
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }

  async function getTemplateById(id) {
    if(id === undefined) {
      id = ""
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL+ "/template/search/id?id=" + id
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }

  async function addFeedback(feedback) {
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL + "/feedback";
    console.log("*******")
    //console.log(assessment)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(feedback)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              //console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }

  async function getCustomers(chars) {
    if(chars === undefined) {
      chars = ""
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL+ "/customer?chars=" + chars
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }

  async function getUsersByName(chars) {
    if(chars === undefined) {
      chars = ""
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL+ "/users?chars=" + chars
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }

  async function getCustomerById(id) {
    if(id === undefined) {
      id = ""
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL+ "/customer/search/id?id=" + id
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }

  async function getAssessmentsByCustomer(id) {
    if(id === undefined) {
      id = ""
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL+ "/assessment/search/customer?id=" + id
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }

  async function getRecommendationsById(id) {
    if(id === undefined) {
      id = ""
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL+ "/assessment/recommendation?id=" + id
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }

  async function addRecommendationsById(id) {
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL + "/assessment/recommendation?id=" + id;
    console.log("*******")
    //console.log(assessment)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({"id": id})
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              //console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }

  async function getReadout(assessmentId) {
    if(assessmentId === undefined) {
      assessmentId = ""
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CJN_API_SERVICE_URL+ "/readout?filekey=" + assessmentId
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }

  export { 
    addAssessment, 
    updateAssessment, 
    getAssessmentById, 
    getAssessmentByAlias, 
    getTemplateById, 
    addFeedback,
    getCustomers,
    getCustomerById,
    getAssessmentsByCustomer,
    getRecommendationsById,
    addRecommendationsById,
    getReadout,
    getUsersByName
  }