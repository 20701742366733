import 'survey-core/defaultV2.min.css';
import { Model, StylesManager, Styles } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { Converter } from "showdown";

import {
  Button,
  Header,
  SpaceBetween
}
from "@awsui/components-react";

import * as React from "react";


import { useLocation } from "react-router-dom";

import surveyTemplate from '../components/templates/assement-default'

import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";

import { useEffect, useState, } from 'react';

import { getAssessmentById, getTemplateById, updateAssessment, addRecommendationsById } from "../utils/Api";

import BottomCharts from '../components/BottomCharts';

import "survey-core/defaultV2.css";

StylesManager.applyTheme("orange");

let model = null


let SurveyWrapper = (props) => {

    const state = useLocation();
    const navigate = useNavigate();
  
      //console.log(state)
     
  
      const [assessment, setAssessment ] = useState({})
  
      const [ templateData, setTemplateData ] = useState(null)
  
      const [ id, setId ] = useState(state['state']['id'])
  
      //const [scores, setScores] = useState()
  
      const [val, setVal ]= useState(0)
  
      useEffect(() => {
        //console.log('getting assessment')
        //console.log(id)
        //console.log(id)
        getAssessmentById(id).then(
          (assessment) => {
              //console.log(result)
              setAssessment(assessment)
              props.setCustomerName(assessment['customerName'])
              getTemplateById(assessment.template).then(
                (template) => {
                    //console.log(result)
                    setTemplateData(template.data)
                    //console.log(template)
                },
                (error) => {
                    console.log("error")
                    console.log(error);
                }
              )
          },
          (error) => {
              console.log("error")
              console.log(error);
          }
        )
          
        
      }, []);
      
      let updateAssessmentWrapper = (assessmentObj) => {
          //console.log(assessmentObj)
          updateAssessment(assessmentObj).then(
            (result) => {
              //console.log(result)
              //console.log(id)
              addRecommendationsById(id).then(
                (result) => {
                },
                (error) => {
                  console.log(error)
                }
              )
            },
            (error) => {
                console.log(error)
            }
        ).then(
          
        )
      }
      let  saveAssessment = (saveAssessment, navigate) => {
        /* let assessmentID = prompt("Please enter a unique name for this assessment - you will need to remember it to reload it later.", "CJN-");
        if (assessmentID != null) {
            assessmentID.replace(/\s/g, '.')
            var saveData = {
                key : assessmentID,
            } */
            //console.log(saveAssessment)
            updateAssessmentWrapper(saveAssessment)
            //navigate('/assessments/')
            //console.log(saveData)
            /* var xhr = new XMLHttpRequest();
            xhr.open("POST", "https://7wr4p6us2h.execute-api.us-east-1.amazonaws.com/assessments");
            xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
            xhr.send(JSON.stringify(saveData));
            alert("Assessment saved as: "+assessmentID); */
            //getRecommendationsById(assessmentID);
            //refreshCategoryStatuses();
        //}
      };
  
      let handlePageChange = (survey, options) => {
        let newAssessment = assessment
        newAssessment['surveyData'] = survey.data
        newAssessment['currentPage'] = survey.currentPageNo
        setAssessment(newAssessment)
        updateAssessmentWrapper(newAssessment)
      }
      
  
      const surveyComplete = (sender) => {
        let newAssessment = assessment
        //console.log(newAssessment)
        newAssessment['status'] = 'Complete'
        setAssessment(newAssessment)
        saveAssessment(
          newAssessment,
          navigate
        )
      };
  
      let handleValueChange = (survey) => {
        //console.log('value Changing')
        let pScore = refreshCategoryStatuses(survey);
        let cjnScore = getCjnScore(pScore)
        let newAssessment = assessment
        newAssessment['surveyData'] = survey.data
        newAssessment['currentPage'] = survey.currentPageNo
        //console.log(cjnScore)
        newAssessment['score'] = {
          "total": cjnScore.toFixed(3),
          "BusinessAndPeople": pScore[1].toFixed(2),
          "Security": pScore[2].toFixed(2),
          "Platform": pScore[3].toFixed(2),
          "OperationsAndGovernance": pScore[4].toFixed(2)
        }
        console.log(newAssessment)
        setAssessment(newAssessment)
        updateAssessmentWrapper(newAssessment)
        /* recPage.addElement({
          "type": "text",
          "name": "hello",
          "title": "hiThe recommended concepts below are in order based on your current level of concept adoption in each functional perspective. As the next step, please mark the recommended concepts you identify as priorities with a checkmark. Once you have identified the priority concepts, identify the date you would like to complete the concepts in the field below.",
          "hideNumber": true
         }) */
        //let choiceOne = [{"value": "Hello"}]

        let questionJSON =  {"type": "checkbox",
        "name": "RecommendationsPriorities",
        "title": "Recommendations and Resources for Not Started or In Progress ",
        "hideNumber": true,
        "choices": [
         {
          "value": "value",
          "text": "choice"
         },
         "Please Save Your Assessment"
        ],
        "showSelectAllItem": true
       }



        //console.log(choices)
        //console.log(priorityRecommendations.choices)
        //priorityRecommendations.choices.push({"value": "Hi", "text": "Hi"})
        //priorityRecommendations.choices = choiceOne
        //priorityRecommendations.choices[0].visible =true
        //console.log(priorityRecommendations)
        /* //priorityRecommendations.choices.push("Test")
        var priorityChoices = []

        var recommendationHtml = "<b>"+ "Test"  + "</b> ("
        + "Stage" +  " / "  + "Perspective"  + " / "
        + "<i>"+"Test"+"</i>)"
        + "<p>"+"Test" +"</p>"
        + "<p><b>Recommendations and Resources</b><br>"
        + "Test" +"</p><br>" 

        var mdValue = "TestVal";
        var recObject = {};
        recObject["value"] = "Test";
        recObject["text"] = "Test";
        recObject['visibleIf'] = true;
        priorityChoices.push(recObject)

        console.log("pushing recs")
        priorityRecommendations.choices.push(priorityChoices)
        priorityRecommendations.choices.visible = (true) */

        
        props.onValueUpdate(pScore)

        
        /* setScores({ ...scores, 
          "BusinessAndPeople": pScore[1], 
          "Security": pScore[2],
          "Platform": pScore[3],
          "OperationsAndGovernance": pScore[4]
        })   */
      }

      let getCjnScore = (pScore) => {
        let p1 = pScore[1]
        let p2 = pScore[2]
        let p3 = pScore[3]
        let p4 = pScore[4]
       
       let cjnScore = Math.min(Math.floor(p1),Math.floor(p2),Math.floor(p3),Math.floor(p4));
       let  cjnFractionScore = getCjnFraction(p1,cjnScore) + 
                               getCjnFraction(p2,cjnScore) + 
                               getCjnFraction(p3,cjnScore) + 
                               getCjnFraction(p4,cjnScore); 
       return cjnScore + cjnFractionScore;
      }
  

      let  getCjnFraction = (p,currentStage) => {
        if (Math.floor(p) > currentStage) {
            return(0.25);
        } else 
        {
            return((p-Math.floor(p))/4);
        }
      }
      
      let  refreshCategoryStatuses = (survey) =>{
        const pScore = [];
        const pChecked = [];
        const pTotal = [];
        
            let iPerspective
            for(iPerspective=1;iPerspective<=maxPerspective;iPerspective++) {
                //console.log('looping')
                pScore[iPerspective] = 1.0;
                var keepAddingToScore = true;
                var allMature = true;
                let iStage
                let competencies
                for(iStage=1;iStage<=maxStage;iStage++) {
                    var questionName = "competency_"+iStage.toString()+"_"+iPerspective.toString();
                    var statusName = "status_"+questionName;
                    var q = survey.getQuestionByName(questionName);
                    competencies = q.choices;
                    var totalCount = competencies.length;
                    var checkedCount = q.value.length;
                    pChecked[questionName]=checkedCount;
                    pTotal[questionName]=totalCount;
                    var percentComplete=checkedCount/totalCount;
        //            var q = survey.getQuestionByName(statusName);
                    if (percentComplete == 0) {
        //                q.html =  "Not started";
                        allMature = false;
                    } else if (percentComplete > 0.500) {
        //                q.html =  "Mature";
                        if (keepAddingToScore) {
                            pScore[iPerspective] = pScore[iPerspective] + 1;
                        }
                    } else {
        //                q.html =  "In progress";
                        allMature=false;
                        if (keepAddingToScore) {
                            pScore[iPerspective] = pScore[iPerspective] + 0.500;
         
                       }
                    }
                    if (!allMature) { 
                        keepAddingToScore = false;
                    }
                }
            }
            //console.log(pScore[1],pScore[2],pScore[3],pScore[4]);
  
            return pScore
        }
  
      
      let maxStage
      let maxPerspective
      let getModel = (json) => {
        const converter = new Converter()
        
        model = new Model(json);
        model.onComplete.add(surveyComplete);
        //setModelObj(model)
        model.data = assessment.surveyData
        //console.log('setting current page')
        //console.log(assessment)
        model.currentPageNo = assessment.currentPage
        //console.log(model.data)
        model.onTextMarkdown.add(function(survey, options){
          // Convert Markdown to HTML
          let str = converter.makeHtml(options.text);
          // Remove root paragraphs <p></p>
          str = str.substring(3);
          str = str.substring(0, str.length - 4);
          // Set HTML markup to render
          options.html = str;
        });
  
        model.onCurrentPageChanging.add(handlePageChange)
        model.onStarted.add(handlePageChange)
        model.onValueChanged.add(handleValueChange)
        let maxVals  = getAssessmentMaxValues(model)
        maxStage = maxVals[0]
        maxPerspective = maxVals[1]
        
        return (<Survey model={model}/>);
      }
      
  
      return (
        <div>
          <Header
            variant="h1"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => {
                    //console.log(assessment)
                    updateAssessmentWrapper(assessment)
                    //console.log(saveData)
                    //setVal(1)
                    //props.onValueUpdate(5)
                    //let pScore = refreshCategoryStatuses(survey);
                    //props.onValueUpdate(pScore)
                    //let pScore =[1,2,3,4,5]
                    //props.onValueUpdate(pScore)
                    
                }}
                >
                  Save
                </Button> 
              </SpaceBetween>
            }
          >
            
          </Header>
          <div style={{
            paddingBottom: "100px"
          }}>
            {templateData ? getModel(templateData): null}
          </div>
          
          
          
        </div>
      
      );
    
     // 
  
  }
  

  
  
  
  
  
  let  getAssessmentMaxValues = (survey) => {
    //console.log(survey.getAllQuestions())
    let maxStage = 0
    let maxPerspective = 0
    var allQuestions = survey.getAllQuestions();
        for (let i=0;i<allQuestions.length;i++) {
            let question = allQuestions[i].propertyHash.name;
            
            if (question.substring(0,11) == 'competency_') {
              //console.log(question.substring(0,11))
              //console.log(question)
                let fragmentStage = (question.substring(11,255));
                let fragmentPerspective = fragmentStage.substring(fragmentStage.search("_")+1,255)
                fragmentStage = fragmentStage.substring(0,fragmentStage.search("_"));
                //console.log('frag')
                //console.log(fragmentStage)
                maxStage=Math.max(maxStage, Number(fragmentStage));
                //console.log(maxStage)
                maxPerspective = Math.max(maxPerspective, Number(fragmentPerspective));
            }
        }
      //console.log('max stage')
      //console.log(maxStage)
      //console.log('max perspective')
      //console.log(maxPerspective)
      return [maxStage, maxPerspective]
    };
  

export default React.memo(SurveyWrapper)