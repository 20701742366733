import {
    Button,
    Header,
    SpaceBetween,
    Tabs,
    Box,
    ColumnLayout,
    Container,
    StatusIndicator,
    ProgressBar,
    Link,
    Popover
  }
  from "@awsui/components-react";

import * as React from "react";

import { useLocation } from "react-router-dom";

import { useEffect, useState, } from 'react';

import AssessmentDetails from "../components/AssessmentDetails";

import { getCustomerById, getAssessmentsByCustomer } from "../utils/Api";

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );

let CustomerView = () => {

    const location = useLocation();

    const [ customerDetails, setCustomerDetails ] = useState({})
    const [ assessments, setAssessments ] = useState([])
    const [tabs, setTabs ] = useState([])

    let customerId = location.state.customerId

    let SFDCBaseUrl = "https://aws-crm.lightning.force.com/lightning/r/Account/"

    useEffect(() => {
        console.log(customerId)
        getCustomerById(customerId).then(
            (result) => {
                setCustomerDetails(result)
                console.log(result)
            },
            (error) => {
                console.log(error);
                //setLoading(false);
            }
        );
        getAssessmentsByCustomer(customerId).then(
            (result) => {
                console.log(result)
                let newTabs = []
                result.forEach(e => {
                    newTabs.push(
                        {
                            label: e.created,
                            id: e.id,
                            content: <AssessmentDetails assessment={e}/>
                        },
                    )
                });
                setTabs(newTabs)
                setAssessments(result)
                //setCustomerDetails(result)
                //console.log(result)
            },
            (error) => {
                console.log(error);
                //setLoading(false);
            }
        );
        
    }, []);


    return (
        <div>
             <Container header={<Header variant="h2">{customerDetails.name}</Header>}>
                <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween size="l">
                    <ValueWithLabel label="Salesforce">
                        <Link href={SFDCBaseUrl + customerDetails.sfdcId + "/view"} external={true}>
                            Open
                        </Link>
                    </ValueWithLabel>
                    <ValueWithLabel label="Customer Point of Contact">
                        {customerDetails.primaryContact}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="CJN Score">
                        {assessments[0]?.score?.total}
                    </ValueWithLabel>
                    <ValueWithLabel label="Contact Email">
                        {customerDetails.contactEmail}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                <ValueWithLabel label="Account Id">
                        {customerDetails.accountId}
                    </ValueWithLabel>
                </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Tabs
                tabs={tabs}
            />
        </div>

    )

}


export default CustomerView;